import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import TouchWithUs from "../components/TouchWithUs";
import Footer from "../components/Footer";

const contact = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero className="general-hero contact-hero" text="Contact Us" />
      <TouchWithUs />
      <Footer />
    </div>
  );
};

export default contact;
