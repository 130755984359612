import React from "react";
import BlueBgBtn from "./BlueBgBtn";

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="container padding-container">
        <div className="text-content">
          <div className="top">
            <img src="/images/stethoscope.png" alt="Stethoscope" />
            <h2>
              About <span>Us</span>
            </h2>
          </div>

          <h3>Our Approach</h3>
          <div>
            <h4>Our Staff </h4>
            <p>
              Professionally we serve, and personally we care. Our 60+ staff
              members serve our community with care and pride.{" "}
            </p>
          </div>

          <div>
            <h4>Our Story </h4>
            <p>
              With 17 beds and a designated Critical Access Hospital, we care
              for Carnegie and the surrounding community, demonstrating great
              professionalism and personalized care!
            </p>
          </div>

          <BlueBgBtn to="/about" text="Learn more" />
        </div>
        <div className="img-container">
          <div className="first-img">
            <img src="/images/about-us-1.jpg" alt="About" />
          </div>
          <div className="col">
            <div className="second-img">
              <img src="/images/about-us-2.jpg" alt="About" />
            </div>
            <div className="third-img">
              <img src="/images/about-us-3.jpg" alt="About" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
