import React from "react";
import GeneralNav from "../components/GeneralNav";
import "../static/clinic/clinic.css";
import Footer from "../components/Footer";
import { IoIosMail } from "react-icons/io";
import { PiPhoneCallFill } from "react-icons/pi";
import { FaLocationDot } from "react-icons/fa6";
import Appointment from "../components/Appointment";

const clinic = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <section className="clinic-hero">
        <div className="container padding-container">
          <div className="logo-container">
            <img src="/images/clinic-logo.png" alt="Clinic Logo" />
          </div>
          <h2>Carnegie Medical Clinic</h2>
          <h3>Walk-Ins Welcome</h3>
        </div>
      </section>
      <section className="clinic-services">
        <div className="container padding-container">
          <div className="clinic-card">
            <img
              src="/images/rehabilitation-3.jpg"
              alt="Doctor taking care of adult patient"
            />

            <div className="col">
              <h3>Family Care</h3>
              <p>
                Annual checkups, chronic illnesses, medication management, and
                sudden illnesses or ailments—we are your primary care provider
                for the whole family.
              </p>
            </div>
          </div>
          <div className="clinic-card">
            <img
              src="/images/swingbed-2.jpg"
              alt="Doctor taking care of adult patient"
            />

            <div className="col">
              <h3>Health & Wellness</h3>
              <p>
                Are you plagued by unexplained fatigue, pain, or other symptoms?
                You may have an imbalance that can be easily corrected with
                vitamin or medication therapy.
              </p>
            </div>
          </div>
          <div className="clinic-card">
            <img
              src="/images/telehealth.jpg"
              alt="Patient talking with a doctor on their laptop"
            />

            <div className="col">
              <h3>Telehealth</h3>
              <p>
                Are you feeling under the weather, but are unable or don’t want
                to come into the office? Our telehealth evaluations connect you
                with a doctor where you are.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="clinicians">
        <div className="container padding-container">
          <h1>
            Meet Our <span>Clinicians</span>
          </h1>
          <div className="row">
            <div className="img-container">
              <img
                src="/images/kristin-hobbs.jpg"
                alt="Kristin Hobbs smiling"
              />
            </div>
            <div className="col">
              <p>
                Kristyn Hobbs, APRN-CNP, is a new face to the Carnegie Clinic,
                and we’re happy to have her. She has been a practicing Nurse
                Practitioner for two years and a Registered Nurse for nine
                years. She began her education at South Western Oklahoma State
                University, where she earned a BSN. She achieved her Master of
                Nursing from the University of Phoenix. After working in an ER
                and teaching nursing for a short time, she made the decision to
                pursue her Nurse Practioner License, which she did at Caplan
                University. She feels the role of NP allows her to make a bigger
                impact in the rural community of Carnegie and enjoys being
                involved in the day-to-day lives of her patients
              </p>
              <div className="col">
                <h3>Kristyn Hobbs</h3>
                <span>APRN-CNP</span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="highlighted-services">
        <div className="container padding-container">
          <h1>
            Highlighted <span>Services</span>
          </h1>
          <div className="row">
            <div className="higlighted-card">
              <img src="/images/Physical.png" alt="icon" />
              <h3>Primary Care</h3>
              <p>
                We offer health services that cover a range of prevention,
                wellness, and treatment for common illnesses.
              </p>
            </div>

            <div className="higlighted-card">
              <img src="/images/women-health.png" alt="icon" />
              <h3>Women's Health</h3>
              <p>
                We offer a myriad of women’s health services, including annual
                checkups, pap smears, breast exams, and prescribing birth
                control.
              </p>
            </div>
            <div className="higlighted-card">
              <img src="/images/wellchild.png" alt="icon" />
              <h3>Well-Child Check</h3>
              <p>
                We offer well-child checks to evaluate our littlest patients and
                ensure they’re on the right track to grow into healthy adults.
              </p>
            </div>
            <div className="higlighted-card">
              <img src="/images/Physical.png" alt="icon" />
              <h3>Physicals</h3>
              <p>
                Our physicals are a complete workup of each patient, ensuring
                they are fit for everyday activities and/or athletic exertion.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-information">
        <div className="container padding-container">
          <h1>
            Contact <span>Information</span>
          </h1>

          <div className="row">
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13055.908672979771!2d-98.60177!3d35.107281!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ac4e477c361063%3A0xcc44c0858b1197b5!2s101%20Ash%20St%2C%20Carnegie%2C%20OK%2073015!5e0!3m2!1sen!2sus!4v1718102984529!5m2!1sen!2sus"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col">
              <div className="row">
                <div className="icon-container">
                  <IoIosMail className="icon" />
                </div>
                <div className="col">
                  <span>Office Hours</span>
                  <h4>Monday - Friday 8:00 AM to 5:00 PM</h4>
                </div>
              </div>
              <div className="row">
                <div className="icon-container">
                  <PiPhoneCallFill className="icon" />
                </div>
                <div className="col">
                  <span>Phone</span>
                  <h4>580-654-4444</h4>
                </div>
              </div>
              <div className="row">
                <div className="icon-container">
                  <FaLocationDot className="icon" />
                </div>
                <div className="col">
                  <span>Location</span>
                  <h4>101 W. Ash St. Carnegie, OK 73015</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default clinic;
