import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/services/services.css";

const psychiatric = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero psychiatric-hero"
        text="Psychiatric Services"
      />
      <section className="psychiatric">
        <div className="container padding-container">
          <div className="row">
            <div className="col">
              <h1>Psychiatric Services</h1>
              <p>
                One in four Americans experiences a mental illness or substance
                abuse disorder each year, often accompanying another illness or
                condition. At Carnegie Tri-County Municipal Hospital, we provide
                essential behavioral health care services to the Carnegie
                community. Our physicians provide psychiatric evaluations and
                medication management for individuals experiencing the symptoms
                of depression, anxiety and other mental health conditions.
              </p>
              <p>
                Carnegie Tri-County Municipal Hospital's licensed social worker
                is also available for crisis intervention, supportive and
                adjustment counseling, psycho-social evaluations and treatment
                planning, and referrals to local support groups.
              </p>
              <p>
                At Carnegie Tri-County Municipal Hospital we care for the whole
                person, which means we not only take your physical health
                seriously, we take your mental health seriously. While in our
                care you will always be treated with professionalism and
                respect.
              </p>
            </div>
            <div className="img-container">
              <img src="/images/psychiatric-service.jpg" alt="Stock" />
            </div>
          </div>
          <p>
            Our nurses, physicians, and clinical staff begin with a review of
            each patient’s medical history. The patient’s individualized care
            plan is determined by an examination and assessment considering all
            relevant factors. This thorough consideration prior to treatment,
            along with a thoughtful and continually monitored treatment plan,
            sets our patients up for successful healing.
          </p>

          {/* strong mind program added */}
          <h1>Strong Mind Program </h1>
          <p>
            We treat all mental health diagnoses. Our approach combines group psychotherapy with regular psychiatric visits. Patients see the psychiatrist at least monthly but can be seen weekly if needed.
            We provide transportation to the host hospital within a 50-mile radius.
          </p>
          <p>
            Our program is covered by Medicare. Most people who have a Medicare supplement will not have a co-pay.
            We can accept private insurance and will verify through the hospital prior to admission to report any potential costs to the patient.
          </p>
          <p>
            We can accept referrals from skilled nursing homes, assisted living facilities, home health agencies, senior nutrition sites, etc.
            We do not need a doctor’s order for admission. Individual and family therapy is available for group members.
          </p>
          <div className="program-locations">
            <h3>Program Locations:</h3>
            <ul>
              <li>Pawhuska, OK</li>
              <li>Prague, OK</li>
              <li>Carnegie, OK</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default psychiatric;
