import React from "react";

import "../static/main.css";
import "../static/home/home.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiOutlineXMark } from "react-icons/hi2";
import { RiArrowDropDownLine } from "react-icons/ri";
import clinic from "../html/clinic";

const HomeNav = () => {
  const [scrolled, setScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("DOMContentLoaded", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const navClasses = `${"navigation"} ${scrolled ? "scrolled" : "navigation"}`;
  const blackText = ` ${scrolled ? "black-text link" : "link"}`;
  const clinicText = ` ${scrolled ? "clinic" : "clinic"}`;
  const logoBlackText = ` ${scrolled ? "black-text " : ""}`;
  const whiteBg = ` ${scrolled ? "sub-services white-bg" : "sub-services"}`;
  const hamburger = ` ${scrolled ? "black-text hamburger" : "hamburger"}`;
  const closeHamburger = ` ${
    scrolled ? "black-text close-hamburger" : "close-hamburger"
  }`;

  return (
    <header className={navClasses}>
      <nav className="container padding-container">
        <Link to="/" className="logo">
          <img src="/images/logo.png" alt="Logo" />
          <h1 className={logoBlackText}>
            Carnegie Tri-County <br /> Municipal Hospital
          </h1>
        </Link>
        <ul className={isOpen ? "navOpen" : ""}>
          <li>
            <Link to="/" className={blackText}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className={blackText}>
              About Us
            </Link>
          </li>
          <li id="services">
            <Link to="/services" className={blackText}>
              Services
            </Link>
          </li>
          {/* <li>
            <Link to="/appointment" className={blackText}>
              Appointment
            </Link>
          </li> */}
          <li>
            <Link to="/contact" className={blackText}>
              Contact Us
            </Link>
          </li>

          <li>
            <Link
              to="https://www.securebillpay.net/carnegietricounty"
              className={blackText}
            >
              Make a Payment
            </Link>
          </li>
          <li>
            <Link to="/employment" className={blackText}>
              Employment
            </Link>
          </li>
          <li>
            <Link to="/clinic" className={clinicText}>
              Carnegie Medical Clinic
            </Link>
          </li>
        </ul>
        <div
          className={`hamburger-container ${
            isOpen ? "hidden" : "hamburger-container"
          }`}
          onClick={toggleMenu}
        >
          <RxHamburgerMenu className={hamburger} />
        </div>
        <div
          className={`close-hamburger-container ${
            !isOpen ? "hidden" : "hamburger-container"
          }`}
          onClick={toggleMenu}
        >
          <HiOutlineXMark className={closeHamburger} />
        </div>
      </nav>
    </header>
  );
};

export default HomeNav;
