import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import ServiceCard from "../components/ServiceCard";
import "../static/main.css";
import "../static/services/services.css";
import TouchWithUs from "../components/TouchWithUs";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const services = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero className="general-hero services-hero" text="Services" />
      <section className="services">
        <div className="container padding-container">
          <div className="main">
            <Link to={"/services/emergency"} className="service-link">
              <ServiceCard
                img="/images/emergency.svg"
                title=" Emergency"
                content="Our emergency room is staffed 24/7 with highly skilled
              medical professionals, ensuring prompt and efficient care in the event of a medical emergency."
              />
            </Link>

            <Link to={"/services/x-ray"} className="service-link">
              <ServiceCard
                img="/images/radiology.svg"
                title="X-ray and CT (Outpatient & Inpatient)"
                content=" At Carnegie Tri-County Municipal Hospital, we are committed to
                providing premier quality imaging with compassion and care.
                Whether it’s a CT scan or a traditional X-ray... "
              />
            </Link>

            <Link to={"/services/respiratory"} className="service-link">
              <ServiceCard
                img="/images/therapy.svg"
                title="Respiratory Therapy"
                content="Our respiratory therapists are essential members of the multidisciplinary healthcare team, collaborating with the physicians and nurses to provide compassionate, high-quality care to all areas of the hospital."
              />
            </Link>

            <Link to={"/services/lab"} className="service-link">
              <ServiceCard
                img="/images/test-tube.svg"
                title="Lab (Outpatient & Inpatient)"
                content="Carnegie Tri-County Municipal Hospital laboratory services provides
                accurate and timely results while contributing to our patient and
                family-centered care..."
              />
            </Link>

            <Link to={"/services/nursing"} className="service-link">
              <ServiceCard
                img="/images/therapy.svg"
                title="Nursing"
                content="At Carnegie Tri-County Municipal Hospital our nursing staff prides itself on compassionate caregiving and nursing excellence."
              />
            </Link>

            <Link to={"/services/psychiatric"} className="service-link">
              <ServiceCard
                img="/images/psych.svg"
                title="Psychiatric"
                content="At Carnegie Tri-County Municipal Hospital, we provide
                essential behavioral health care services to the Carnegie
                community. Our physicians provide psychiatric...
              "
              />
            </Link>

            <Link className="service-link" to={"/services/swingbed"}>
              <ServiceCard
                img="/images/swingbed.svg"
                title="Swing Bed – Skilled Nursing Care Program"
                content="Carnegie Tri-County Municipal Hospital offers high quality care
                for slow healing and chronic wounds. We provide full assessment,
                tailored treatment, patient education and support. "
              />
            </Link>
            <Link to={"/services/rehabilitation"} className="service-link">
              <ServiceCard
                img="/images/swingbed.svg"
                title="Therapy Services"
                content="Carnegie Tri-County Municipal Hospital provides comprehensive Physical Therapy, Occupational Therapy and Sports Medicine services to help our patients return to an independent lifestyle."
              />
            </Link>

            <Link to={"/services/ultrasound"} className="service-link">
              <ServiceCard
                img="/images/therapy.svg"
                title="Ultrasound"
                content="Ultrasound examinations on all parts of the body are available for both adult and pediatric patients."
              />
            </Link>

            <Link to={"/services/case-management"} className="service-link">
              <ServiceCard
                img="/images/therapy.svg"
                title="Case Management & Social Services"
                content="At Carnegie Tri-County Municipal Hospital, our Case Management and Social Services department is available to assist patients and their families..."
              />
            </Link>
            <Link to={"/services/wound-care"} className="service-link">
              <ServiceCard
                img="/images/emergency.svg"
                title=" Wound Care"
                content="Carnegie Tri-County Municipal Hospital offers high quality care for slow healing and chronic wounds. We provide full assessment, tailored treatment, patient education and support. Our goal is to heal the wound as quickly and efficiently as possible and eliminate wound-related symptoms and complications.
                "
              />
            </Link>
          </div>
        </div>
      </section>

      <TouchWithUs />
      <Footer />
    </div>
  );
};

export default services;
