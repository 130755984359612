import React from "react";

import "../static/careers/careers.css";
import GeneralNav from "../components/GeneralNav";
import CareerCard from "../components/CareerCard";
import { useState, useEffect } from "react";
import GeneralHero from "../components/GeneralHero";
import Footer from "../components/Footer";
import BlueBgBtn from "../components/BlueBgBtn";

const Employment = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from an API endpoint
        const response = await fetch(
          "https://recruiting.paylocity.com/recruiting/v2/api/feed/jobs/a1a7d438-0e57-41ee-a189-926e53aa370f"
        );

        // Convert response to JSON
        const jsonData = await response.json();
        console.log("Fetched data:", jsonData);

        // Update state with the fetched data
        setData(jsonData.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero appointment-hero"
        text="Employment"
      />
      <section className="data">
        <div className="container padding-container">
          <div className="data-card-container">
            {data ? (
              <>
                {data.jobs ? (
                  <div>
                    {data.jobs.map((item) => (
                      <CareerCard
                        key={item.jobId}
                        jobPosition={item.title}
                        jobDescription={item.description}
                        jobLocation={
                          item.jobLocation.address
                            ? item.jobLocation.address
                            : "Unspecified"
                        }
                        time="Full Time"
                        link={item.applyUrl}
                        jobRequirements={item.requirements}
                      />
                    ))}
                  </div>
                ) : (
                  <p>No Available jobs</p>
                )}
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </section>

      <section className="next-move">
        <div className="container padding-container">
          <h1>Ready For Your Next Move ?</h1>
          <p>
            A career at Carnegie Tri-County Municipal Hospital could be around
            the corner.
          </p>
          <BlueBgBtn
            to="mailto:aanderson@chmcok.com"
            text="Send Us Your Resume"
          />
        </div>
      </section>

      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default Employment;
