import React from "react";
import { Link } from "react-router-dom";

const MaroneBgBtn = (props) => {
  return (
    <div className="maroneBgBtn">
      <Link to={props.to} className="marone-btn link">
        {props.text}
      </Link>
    </div>
  );
};

export default MaroneBgBtn;
