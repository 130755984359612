import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import AboutUs from "../components/AboutUs";
import TeamCard from "../components/TeamCard";
import Testimonial from "../components/Testimonial";
import BlogCard from "../components/BlogCard";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/about/about.css";

export const about = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero className="general-hero about-hero" text="About Us" />
      <section className="choose-us">
        <div className="container padding-container">
          <div className="text-content">
            <div className="top">
              <img src="/images/stethoscope.png" alt="Stethoscope" />
              <h2>
                Why <span>Choose</span> Us
              </h2>
            </div>
            <p>
              When it comes to your healthcare needs, choosing the right
              provider is crucial. At Carnegie Tri-County Municipal Hospital, we
              understand the importance of this decision and strive to be your
              trusted partner in health. Choosing Carnegie Tri-County Municipal
              Hospital's programs means choosing compassionate care, specialized
              expertise, and a commitment to the well-being of our patients.
            </p>
            <p>
              We strive to put the needs of our patients first, and we are
              honored to serve the families in our community.
            </p>
          </div>

          <div className="img-container">
            <img src="/images/choose-us.jpg" alt="Doctors Smiling" />
            <div className="blue-container"></div>
          </div>
        </div>
      </section>
      <div className="about-us-container">
        <AboutUs />
      </div>

      <section className="ceo">
        <div className="container padding-container">
          <div className="top">
            <img src="/images/stethoscope.png" alt="Stethoscope" />
            <h2>
              Meet <span>our</span> CEO
            </h2>
          </div>
          <div className="main-ceo-content">
            <div className="img-container">
              <img src="/images/ceo.jpeg" alt="CEO" />
            </div>
            <div className="right-content">
              <h2>Nathan Richmond</h2>
              <p>
                Nathan Richmond has had a career in healthcare for over 15 years
                with progressive leadership responsibilities. Nathan graduated
                from Great Plains Technology Center, Lawton, OK, in 2010 with
                his certificate of completion as a Licensed Practical Nurse.
                Nathan continued his education and completed the associate in
                nursing program at Western Oklahoma State College in 2011.
                Nathan later continued his education earning a bachelor's in
                nursing from Oklahoma City University in 2016 and a master's in
                healthcare administration from Oklahoma State University in
                2019.
              </p>
              <p>
                Nathan is an active member of the American Nurses Association,
                Oklahoma Nurses Association, Oklahoma Organization for Nurse
                Leaders, and the American College for Healthcare Executives.
                Nathan serves on the Oklahoma Nurses Association's Nominating
                Committee and has recently worked with the Oklahoma Hospital
                Association on the workforce development steering group.  Nathan
                is passionate about the health and education of all community
                members.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="team">
        <div className="container padding-container">
          <div className="top-section">
            <div className="top">
              <img src="/images/stethoscope.png" alt="Stethoscope" />
              <h2>
                Our Expert <span>Team</span>
              </h2>
            </div>
          </div>

          <div className="cards-container">
            <TeamCard
              link="linkedin.com"
              bio="Kristyn Hobbs is a certified Family Nurse Practitioner who has been practicing at Carnegie Medical Clinic since 2018. She completed her undergraduate Nursing Degree at Southwestern Oklahoma State University in 2010. She immediately went to work at Great Plains Regional Medical Center as a Labor and Delivery Nurse. Kristyn loved caring for families and children and realized early on this was her passion. She decided to pursue her Nurse Practitioner Degree in Family Practice shortly after. She has experience as a nursing educator, Director of Women’s Services, Emergency Medicine RN, and participates in various health organizations including the Oklahoma Medical Reserve Corp. Kristyn enjoys living and caring for her community, and the positive impact she can have on those around her. She resides at Fort Cobb Lake with her Husband, Corey, and children Cash and Kinley. In her spare time, she enjoys watching sporting events, gardening, going to the lake, and spending time with family and friends."
              name="Kristyn Hobbs "
              position="Certified Family Nurse Practitioner "
              img="/images/kristyn.png"
            />
            <TeamCard
              link="linkedin.com"
              bio="Kristyn Hobbs is a certified Family Nurse Practitioner who has been practicing at Carnegie Medical Clinic since 2018. She completed her undergraduate Nursing Degree at Southwestern Oklahoma State University in 2010. She immediately went to work at Great Plains Regional Medical Center as a Labor and Delivery Nurse. Kristyn loved caring for families and children and realized early on this was her passion. She decided to pursue her Nurse Practitioner Degree in Family Practice shortly after. She has experience as a nursing educator, Director of Women’s Services, Emergency Medicine RN, and participates in various health organizations including the Oklahoma Medical Reserve Corp. Kristyn enjoys living and caring for her community, and the positive impact she can have on those around her. She resides at Fort Cobb Lake with her Husband, Corey, and children Cash and Kinley. In her spare time, she enjoys watching sporting events, gardening, going to the lake, and spending time with family and friends."
              name="Kristyn Hobbs "
              position="Certified Family Nurse Practitioner "
              img="/images/kristyn.png"
            />
            <TeamCard
              link="linkedin.com"
              bio="Kristyn Hobbs is a certified Family Nurse Practitioner who has been practicing at Carnegie Medical Clinic since 2018. She completed her undergraduate Nursing Degree at Southwestern Oklahoma State University in 2010. She immediately went to work at Great Plains Regional Medical Center as a Labor and Delivery Nurse. Kristyn loved caring for families and children and realized early on this was her passion. She decided to pursue her Nurse Practitioner Degree in Family Practice shortly after. She has experience as a nursing educator, Director of Women’s Services, Emergency Medicine RN, and participates in various health organizations including the Oklahoma Medical Reserve Corp. Kristyn enjoys living and caring for her community, and the positive impact she can have on those around her. She resides at Fort Cobb Lake with her Husband, Corey, and children Cash and Kinley. In her spare time, she enjoys watching sporting events, gardening, going to the lake, and spending time with family and friends."
              name="Kristyn Hobbs "
              position="Certified Family Nurse Practitioner "
              img="/images/kristyn.png"
            />
          </div>
        </div>
      </section> */}
      {/* <Testimonial /> */}
      {/* <section className="blogs">
        <div className="container padding-container">
          <div className="top">
            <img src="/images/stethoscope.png" alt="Stethoscope" />
            <h2>
              Recent On <span>Our Blogs</span>
            </h2>
          </div>
          <div className="card-container">
            <BlogCard />
            <BlogCard />
            <BlogCard />
          </div>
        </div>
      </section> */}
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default about;
