import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";

const caseManagement = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero case-management-hero"
        text="Case Management & Social Services"
      />
      <section className="case-management">
        <div className="container padding-container row">
          <div className="col">
            <h1>Case Management & Social Services</h1>
            <p>
              Dealing with an illness or injury can be difficult for patients
              and their loved ones. At Carnegie Tri-County Municipal Hospital,
              our Case Management and Social Services department is available to
              assist patients and their families as they cope with the many
              psychological and social problems that may arise during
              hospitalization and medical treatment.
            </p>
          </div>
          <div className="img-container">
            <img src="/images/case-management-1.jpg" alt="Stock" />
          </div>
        </div>
      </section>
      <section className="case-management-2">
        <div className="container padding-container col">
          <h1>Social Services</h1>
          <h2>
            If your doctor or nurse sees that you might need extra emotional
            support during your hospital stay, they may place a referral to our
            social worker. At Carnegie Tri-County Municipal Hospital, our social
            workers provide support services that may include:
          </h2>
          <div className="row">
            <ul>
              <li>Crisis intervention</li>
              <li>Psychosocial evaluations and treatment planning</li>
            </ul>

            <ul>
              <li>
                Supportive and adjustment counseling, including coping with the
                loss of a loved one
              </li>
              <li>Support group referrals</li>
            </ul>
          </div>
          <p>
            The social worker begins by assessing the patient and determining
            what psychological or social problems they are having due to their
            medical condition.
          </p>
          <p>
            After assessing the patient’s needs, the social worker will
            intervene as needed. Assistance will vary depending on the patient’s
            needs. For example, some patients may need help connecting with
            services in their community, such as a homeless shelter or drug
            treatment support. Other patients may need help understanding their
            medical condition and navigating everything involved with their
            hospital stay.
          </p>
          <p>
            Any concerns related to the psychological, practical or social
            well-being of each patient or their loved ones may be discussed
            openly with our social workers.
          </p>
        </div>
      </section>
      <section className="case-management-3">
        <div className="container padding-container row">
          <div className="img-container">
            <img src="/images/case-management-2.jpg" alt="Stock" />
          </div>
          <div className="col">
            <h1>Case Management</h1>
            <p>
              Our case managers ensure our patients receive the highest quality
              care during and after their stay at Carnegie Tri-County Municipal
              Hospital. By helping patients cope with complicated health or
              medical situations in the most effective way possible, case
              managers assist patients in achieving a better quality of life.
              Our case manager begins by identifying the patient’s goals, needs,
              and resources. From that assessment, the case manager and the
              patient together formulate a plan to meet those goals.
            </p>
          </div>
        </div>
      </section>
      <section className="case-management-2">
        <div className="container padding-container col">
          <h1>
            Our social workers and case managers offer a wide range of <br />
            services, including:
          </h1>
          <div className="row">
            <ul>
              <li>
                Answering questions related to health insurance, Medicaid,
                Medicare, Social Security and state disability
              </li>
              <li>Education and consultative services</li>
              <li>Home healthcare arrangements</li>
            </ul>
            <ul>
              <li>
                Case coordination to help you decide on the best arrangements
                for you when you leave the hospital
              </li>
              <li>Advance directive assistance</li>
              <li>Hospice care arrangements</li>
            </ul>

            <ul>
              <li>Financial assessment and referral services</li>
              <li>Community resources</li>
            </ul>
          </div>
          <p>
            The social worker begins by assessing the patient and determining
            what psychological or social problems they are having due to their
            medical condition.
          </p>
          <p>
            The case management and social services department at Carnegie
            Tri-County Municipal Hospital ensures you experience the highest
            quality care both during and after your stay.
          </p>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default caseManagement;
