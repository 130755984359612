import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/services/services.css";

const nursing = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero className="general-hero nursing-hero" text="Nursing" />
      <section className="nursing">
        <div className="container padding-container">
          <div className="row">
            <div className="col">
              <h1>Nursing</h1>
              <p>
                At Carnegie Tri-County Municipal Hospital our nursing staff
                prides itself on compassionate caregiving and nursing
                excellence. Our nurses provide safe, high-quality patient care
                based on a strong commitment to clinical expertise, education,
                evidence-based practice, and collaboration with
                multidisciplinary team members. Our nursing team engages in
                patient-centered care, which means putting the patient’s needs
                and priorities ahead of their own. Our low nurse-to-patient
                ratios ensure that each patient at Carnegie Tri-County Municipal
                Hospital receives the quality care and personalized attention
                they deserve.
              </p>
            </div>
            <div className="img-container">
              <img src="/images/nursing-service.jpg" alt="Stock" />
            </div>
          </div>
          <div className="col">
            <p>
              Our staffed licensed nurses are experienced in emergency, acute
              and skilled nursing care. Our nurses are BLS (Basic Life Support),
              ACLS (Advanced Cardiovascular Life Support) and PALS (Pediatric
              Advanced Life Support) certified and participate in ongoing
              continuing education to stay abreast of technological advances,
              patient care trends, new and revised protocols, medical
              breakthroughs and research findings
            </p>

            <p>
              As a patient at Carnegie Tri-County Municipal Hospital you can be
              assured of the highest quality nursing care.
            </p>
          </div>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default nursing;
