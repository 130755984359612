import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/services/services.css";

const emergency = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero className="general-hero emergency-hero" text="Emergency" />
      <section className="emergency">
        <div className="container padding-container">
          <div className="row">
            <div className="col">
              <h1>Emergency</h1>
              <p>
                We hope you never need our expert emergency care, but if you do,
                we’re always here. Our team specializes in all types of
                emergency medicine, from broken bones to life-threatening
                injuries or illnesses. Our onsite laboratory and radiology
                departments operate 24/7 to ensure accurate, timely diagnosis.
                In the event of a medical emergency, Carnegie Tri-County
                Municipal Hospital’s Level 4 emergency room team is always
                prepared to provide expert treatment in an environment of
                compassionate care and medical excellence.
              </p>
              <p>
                Our nurses are experienced in emergency, acute and skilled
                nursing care and BLS (Basic Life Support), ACLS (Advanced
                Cardiovascular Life Support) and PALS (Pediatric Advanced Life
                Support) certified.
              </p>
            </div>
            <div className="img-container">
              <img src="/images/emergency-service.jpg" alt="Stock" />
            </div>
          </div>
          <p>
            Should you need more specialized emergency care, our team partners
            with and is experienced at stabilizing and transporting critical
            patients to acute care facilities such as St. John Health System,
            Saint Francis Health System, Hillcrest Medical Center and Oklahoma
            State University Medical Center. No matter the circumstances,
            Carnegie Tri-County Municipal Hospital ensures its emergency
            patients receive the highest standard of care their emergency
            requires.
          </p>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default emergency;
