import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/services/services.css";

const swingbed = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero swingbed-hero"
        text="Swing Bed – Skilled Nursing Care Program"
      />
      <section className="swingbed">
        <div className="container padding-container row">
          <div className="swingbed-1">
            <p>
              After surgery or hospitalization, some patients require additional
              care and are transferred to a nursing home or rehabilitation
              facility to help improve their physical functioning. At Carnegie
              Tri-County Municipal Hospital, we can “swing” you from the
              hospital into our Swing Bed Program, which includes skilled
              nursing, physical rehabilitation and other services based on your
              treatment plan. Even if you were hospitalized elsewhere, you can
              transfer to the Swing Bed Program at Carnegie Tri-County Municipal
              Hospital, bringing you closer to your family.
            </p>
            <h1>Who Qualifies for Swing Bed</h1>
            <p>
              Patients who would benefit from Carnegie Tri-County Municipal
              Hospital’s Swing Bed Program are those who have already been in
              the hospital for at least 3 days but require additional nursing
              care and/or rehabilitation services on a daily basis. Stays
              usually last from one to three weeks.
            </p>
          </div>
          <div className="img-container">
            <img src="/images/swingbed-1.jpg" alt="Stock" />
          </div>
        </div>
      </section>
      <section className="example">
        <div className="container padding-container">
          <h1>
            Examples of patients that would be appropriate for our program are:
          </h1>
          <div className="example-list row">
            <ul>
              <li>
                Those needing care after orthopedic surgery, such as joint
                replacement or fractures
              </li>
              <li>Patients recovering from an accident, injury or illness</li>
            </ul>
            <ul>
              <li>Patients needing to continue or complete IV therapy</li>
              <li>Post-surgical patients</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="expect">
        <div className="container padding-container row">
          <div className="img-container">
            <img src="/images/swingbed-2.jpg" alt="Stock" />
          </div>
          <div className="col">
            <h1>What to Expect</h1>
            <p>
              Our focus is to improve your functional, nutritional and medical
              health so you can return home. Our team of doctors, nurses,
              rehabilitation therapists (physical, occupational and
              respiratory), along with a social worker, dietitian and case
              manager will work with you, your family and your physician to
              create a customized treatment plan designed to help achieve your
              treatment goals.
            </p>
            <h1>Why Choose Carnegie Tri-County Municipal Hospital</h1>
            <div className="row">
              <ul>
                <li>Low patient-to-nurse ratio</li>
                <li>Physical, Occupational and Respiratory Therapists</li>
                <li>In-house laboratory and imaging</li>
              </ul>
              <ul>
                <li>Readily available physicians</li>
                <li>In-house 24-hour emergency care</li>
                <li>Close to your home, family and friends</li>
              </ul>
            </div>
            <p>
              Recover from your illness or injury close to home in the
              comfortable, healing environment of Carnegie Tri-County Municipal
              Hospital.
            </p>
          </div>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default swingbed;
