import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import "../static/appointment/appointment.css";
import Footer from "../components/Footer";

const appointment = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero appointment-hero"
        text="Appointment"
      />
      <section className="appointment-section">
        <div className="container padding-container">
          <div className="form-container">
            <h2>
              Book <span>An Appointment</span>
            </h2>
            <p>You can an appointment online by filling this form below.</p>
            <form action="#" method="post">
              <div className="row">
                <div className="col">
                  <label htmlFor="fname">First Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="fname"
                    id="fname"
                  />
                </div>
                <div className="col">
                  <label htmlFor="lname">Last Name</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lname"
                    id="lname"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                  />
                </div>
                <div className="col">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    placeholder="Phone"
                    name="phone"
                    id="phone"
                  />
                </div>
              </div>
              <div className="col">
                <label htmlFor="department">
                  Choose Appointment Department
                </label>
                <select name="department" id="department">
                  <option value="" disabled selected>
                    Department
                  </option>

                  <option value="inpatient">In-patient</option>
                  <option value="outpatient">out-patient</option>
                </select>
              </div>

              <div className="col">
                <label htmlFor="date">Appointment Date</label>
                <input type="date" placeholder="Date" />
              </div>
              <button type="submit">Book Now</button>
            </form>
          </div>
          <div className="img-container">
            <img src="/images/appointment-img.jpg" alt="Appointment" />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default appointment;
