import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/services/services.css";

const respiratory = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero respiratory-hero"
        text="Respiratory Therapy"
      />
      <section className="respiratory-1">
        <div className="container padding-container row">
          <div className="col">
            <h1>Respiratory Therapy</h1>
            <p>
              The Respiratory Therapy department helps manage the care of
              patients with cardiopulmonary issues such as:
            </p>
            <div className="row">
              <ul>
                <li>Asthma</li>
                <li>COPD</li>
                <li>Pneumonia</li>
              </ul>
              <ul>
                <li>Cardiovascular Disorders</li>
                <li>Trauma</li>
              </ul>
            </div>
            <p>
              Our respiratory therapists are essential members of the
              multidisciplinary healthcare team, collaborating with the
              physicians and nurses to provide compassionate, high-quality care
              to all areas of the hospital. Our clinicians use high-tech
              equipment and the latest medical procedures to diagnose and treat
              our patients.
            </p>
          </div>
          <div className="img-container">
            <img src="/images/respiratory-service.jpg" alt="Stock" />
          </div>
        </div>
      </section>
      <section className="respiratory-licensed">
        <div className="container padding-container col">
          <h1>
            Our licensed respiratory therapists perform <br /> therapeutic and
            life support procedures including:
          </h1>
          <div className="row">
            <ul>
              <li>Administration of oxygen and aerosol medications</li>
              <li>Pulmonary function testing</li>
              <li>
                Stabilizing, treating and managing patients during
                hospital-to-hospital transport
              </li>
            </ul>
            <ul>
              <li>Breathing treatments</li>
              <li>Initiation and management of mechanical ventilation</li>
              <li>Chest physical therapy</li>
            </ul>
            <ul>
              <li>Life support</li>
              <li>Asthma education</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default respiratory;
