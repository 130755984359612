import React from "react";
import "../static/main.css";
const GeneralHero = (props) => {
  return (
    <section className={props.className}>
      <div className="container padding-container">
        <p>
          <span>{props.text}</span>
        </p>
      </div>
    </section>
  );
};

export default GeneralHero;
