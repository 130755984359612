import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";

const woundCare = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero className="general-hero wound-care-hero" text="Wound Care" />
      <section className="wound-care-1">
        <div className="container padding-container row">
          <div className="col">
            <h1>Wound Care</h1>
            <p>
              Carnegie Tri-County Municipal Hospital offers high-quality care
              for slow healing and chronic wounds. We provide a full assessment,
              tailored treatments, patient education and support. Our goal is to
              heal the wound as quickly and efficiently as possible and
              eliminate wound-related symptoms and complications.
            </p>
          </div>
          <div className="img-container">
            <img src="/images/wound-care-service.jpg" alt="Stock" />
          </div>
        </div>
      </section>
      <section className="wound-care-2">
        <div className="container padding-container col">
          <h1>
            We treat a variety of conditions that lead to skin <br /> breakdown
            and injury, including:
          </h1>
          <div className="row">
            <ul>
              <li>
                Leg and foot ulcers caused by diabetes or medical conditions
                that impair blood circulation through the arteries or veins
              </li>
              <li>Wounds associated with lymphedema</li>
              <li>Fistulas</li>
              <li>Other non-healing wounds</li>
            </ul>
            <ul>
              <li>
                Pressure ulcers caused by sitting or lying in one position for
                too long
              </li>
              <li>Burns</li>
              <li>Amputations</li>
            </ul>
            <ul>
              <li>
                Tissue injury caused by radiation therapy for cancer, such as
                injury to the bladder, intestines, rectum, or skin
              </li>
              <li>Cellulitis</li>
              <li>Non-Healing Surgical Wounds</li>
            </ul>
          </div>
          <p>
            Our nurses, physicians, and clinical staff begin with a review of
            each patient’s medical history. The patient’s individualized care
            plan is determined by an examination and assessment considering all
            relevant factors. This thorough consideration prior to treatment,
            along with a thoughtful and continually monitored treatment plan,
            sets our patients up for successful healing.
          </p>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default woundCare;
