import React from "react";

const ServiceCard = (props) => {
  return (
    <div className="service-card">
      <div className="icon">
        <img src={props.img} alt="Service" />
      </div>
      <div className="col">
        <h4>{props.title}</h4>
        <p>{props.content}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
