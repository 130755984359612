import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";
import "../static/services/services.css";

const xRay = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero x-ray-hero"
        text="X-ray and CT (Outpatient & Inpatient)"
      />
      <section className="x-ray">
        <div className="container padding-container row">
          <div className="x-ray-1">
            <p>
              At Carnegie Tri-County Municipal Hospital, we are committed to
              providing premier quality imaging with compassion and care.
              Whether it’s a CT scan or a traditional X-ray, you can expect
              quality care and quick results performed by skilled, registered
              radiology technicians and read by an experienced, board-certified
              radiologist. We offer both inpatient and outpatient radiology
              services 24 hours a day, 7 days a week.
            </p>
            <h1>Diagnostic X-Ray</h1>
            <p>
              Diagnostic radiology procedures use radiation to help identify
              disease or injury. X-rays penetrate body tissues and produce an
              image that provides vital information about normal structures as
              well as diseases in the body. Diagnostic X-rays are used to
              examine all parts of the body. For example, diagnostic X-rays can
              be used to identify problems in the digestive system and kidneys,
              and find evidence of bone fractures and dislocations.
            </p>
          </div>
          <div className="img-container">
            <img src="/images/ct-scan.jpg" alt="Stock" />
          </div>
        </div>
      </section>
      <section className="ct-scan">
        <div className="container padding-container">
          <h2>CT Scan</h2>
          <p>
            A CT (or computerized tomography) scan combines computerized
            technology with a focused X-ray beam that passes through the body at
            various angles, producing clear, cross-sectional images. It is safe,
            fast and painless, and produces precise and accurate images of the
            body. A CT scan is one of a physician’s most important tools for
            examining the bones and internal organs. CT scans are able to detect
            many conditions that conventional X-rays cannot. CT scans can often
            take the place of other more invasive diagnostic techniques, such as
            exploratory surgery, saving the patient discomfort, cost, and
            inconvenience. The scans are also useful for monitoring a patient’s
            progress during or after treatment. CT scanning is considered a
            critical component of any evaluation of brain injuries or function.
            CT scans of the head may also be used to detect or rule out tumors,
            blood clots, and other disorders. These scans can also be especially
            important in diagnosing enlarged lymph nodes, pancreatic disease,
            back problems, or lung cancer.
          </p>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default xRay;
