import React from "react";
import { Link } from "react-router-dom";

const BlueBgBtn = (props) => {
  return (
    <div className="blueBgBtn">
      <Link to={props.to} className="blue-btn link">
        {props.text}
      </Link>
    </div>
  );
};

export default BlueBgBtn;
