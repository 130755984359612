import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Footer from "../components/Footer";
import Appointment from "../components/Appointment";
import "../static/services/services.css";

const lab = () => {
  return (
    <div className="main-container">
      <GeneralNav />

      <GeneralHero
        className="general-hero lab-hero"
        text="Lab (Outpatient & Inpatient)"
      />
      <section className="lab-row-1">
        <div className="container padding-container row">
          <p>
            Carnegie Tri-County Municipal Hospital laboratory services provide
            accurate and timely results while contributing to our patient and
            family-centered care. Laboratory testing is performed 24 hours a
            day, seven days a week by certified laboratory personnel using the
            latest instrumentation. <br />
            <br />
            The laboratory’s rapid turnaround time allows caregivers to treat
            their patients in a timely fashion. We provide laboratory testing
            not just for the hospital, but also local physician’s offices,
            nursing homes and area clinics, accurately and efficiently
            processing approximately 57,000 tests per year.
          </p>
          <div className="img-container">
            <img src="/images/lab-service.jpg" alt="stock" />
          </div>
        </div>
      </section>
      <section className="lab-services">
        <div className="container padding-container col">
          <h3>Our laboratory services include: </h3>
          <div className="row">
            <ul>
              <li>General/Routine Chemistry</li>
              <li>Special Chemistry</li>
              <li>Toxicology Screens (Medical Purposes Only)</li>
              <li>General/Routine Hematology</li>
              <li>Special Hematology</li>
            </ul>
            <ul>
              <li>Hemostasis and Thrombosis</li>
              <li>Flow Cytometry</li>
              <li>Special/Routine/Comprehensive Coagulation</li>
              <li>Microbiology</li>
              <li>Virology</li>
            </ul>
            <ul>
              <li>Parasitology</li>
              <li>Immunology/Serology</li>
              <li>Anatomic Pathology</li>
              <li>Cytology</li>
              <li>Immunohematology</li>
              <li>Blood bank</li>
            </ul>
            <ul>
              <li>Histology</li>
              <li>Urinalysis</li>
              <li>Serology</li>
              <li>Drug Screening for Employment</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="grey-section">
        <div className="container padding-container">
          <p>
            The Carnegie Tri-County Municipal Hospital Clinical Laboratory is
            licensed with the Clinical Laboratory Improvements Amendments
            (CLIA).
          </p>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default lab;
