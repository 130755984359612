import React from "react";
import { useState } from "react";
import axios from "axios";

const TouchWithUs = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email_address: "",
    message: "",
    phone: "",
    subject: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://emails-dp0z.onrender.com/api/contact/",
        formData
      );
      if (response.status === 200 || response.status === 201) {
        console.log("Form sent successfully", response.data);
        setFormData({
          full_name: "",
          email_address: "",
          message: "",
          phone: "",
          subject: "",
        });
      }

      // Optionally, show a success message to the user

      console.log(response.status);
    } catch (error) {
      console.error("Failed to send form", error.message);
      // Optionally, show an error message to the user
    }
  };

  return (
    <section className="touch-with-us">
      <div className="container padding-container">
        <div className="text-content">
          <div className="title">
            <img src="/images/stethoscope.png" alt="Stethoscope" />
            <h2>
              Get <span>In Touch</span> With Us
            </h2>
          </div>
          <p>
            At Carnegie Tri-County Municipal Hospital, we care about you and
            want to make sure you have an exceptional experience when you come
            to us for care. Your comments and suggestions are used to help us
            improve our hospital and patient care services. If you want to
            discuss your experience as a patient or visitor, please feel free to
            email us or use the form to let us know your thoughts.
          </p>
          <h4>
            To schedule an appointment or learn more about our services, <br />
            please contact us on  <span>580-654-1050</span>
          </h4>
          <span>Our outpatient services are available </span>
          <div className="row">
            <div className="col">
              <h4>MONDAY Through FRIDAY</h4>
            </div>
          </div>
          <p>
            For emergency medical needs, our emergency room and hospital are
            open 24/7, ensuring that you always have access to the care you
            need, when you need it.
          </p>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d398.160220054754!2d-98.60532670194728!3d35.107452016825775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ac4e48064e2f9d%3A0x2416815df16e9917!2s102%20N%20Broadway%20St%2C%20Carnegie%2C%20OK%2073015!5e0!3m2!1sen!2sus!4v1718088108694!5m2!1sen!2sus"
              className="map"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="form-container">
          <h4>
            Please do not submit medical questions, personal health information
            and financial information through this form or e-mail.
          </h4>
          <h4>
            If this is a life-threatening emergency, please call 911
            immediately.
          </h4>
          <h3>Send us a message</h3>

          <form onSubmit={handleSubmit} method="POST">
            <input
              type="text"
              placeholder="Full Name"
              value={formData.full_name}
              onChange={handleChange}
              required
              name="full_name"
            />
            <div className="row">
              <input
                type="email"
                placeholder="Email address"
                value={formData.email_address}
                onChange={handleChange}
                required
                name="email_address"
              />
              <input
                type="tel"
                placeholder="Phone number"
                value={formData.phone}
                onChange={handleChange}
                name="phone"
              />
            </div>
            <input
              type="text"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
              name="subject"
            />
            <textarea
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              required
              cols={30}
              rows={10}
              name="message"
            />
            <button type="submit">Send a message</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default TouchWithUs;
