import React from "react";
import "../static/services/services.css";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";

const ultrasound = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero className="general-hero ultrasound-hero" text="Ultrasound" />
      <section className="ultrasound">
        <div className="container padding-container col">
          <div className="row">
            <div className="col">
              <h1>Ultrasound</h1>
              <p>
                Ultrasound examinations on all parts of the body are available
                applications of ultrasound include:
              </p>
              <div className="row">
                <ul>
                  <li>Breast</li>
                  <li>Prostate and other cancer detection</li>
                  <li>Guidance for biopsy</li>
                  <li>Percutaneous (through the skin) aspiration</li>
                </ul>
                <ul>
                  <li>Hernia evaluation</li>
                  <li>Abscess drainage</li>
                  <li>Intraoperative procedures</li>
                  <li>Musculoskeletal (bone, joint and tissue) applications</li>
                </ul>
              </div>
            </div>
            <div className="img-container">
              <img src="/images/ultrasound-service.jpg" alt="Stock" />
            </div>
          </div>
          <p>
            An ultrasound works by using a transducer that produces and receives
            silent, high-frequency sound waves. The transducer is usually a
            hand-held instrument that is placed against the body and slowly
            passes over the area to be examined. Sound waves pass through the
            skin into the body. As the waves strike against various organs, they
            send echoes back to the transducer. The echoes are changed into
            electrical energy by the transducer. The energy shows up as images
            on a display screen. Films of the images are then taken for further
            study and interpretation.
          </p>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default ultrasound;
