import React from "react";
import GeneralNav from "../components/GeneralNav";
import GeneralHero from "../components/GeneralHero";
import Appointment from "../components/Appointment";
import Footer from "../components/Footer";

const Rehabilitation = () => {
  return (
    <div className="main-container">
      <GeneralNav />
      <GeneralHero
        className="general-hero rehabilitation-hero"
        text="Therapy Services"
      />
      <section className="rehabilitation-1">
        <div className="container padding-container row">
          <div className="col">
            <h1>Therapy Services</h1>
            <p>
              Carnegie Tri-County Municipal Hospital provides comprehensive
              Physical Therapy, Occupational Therapy and Sports Medicine
              services to help our patients return to an independent lifestyle.
              We approach every patient understanding that every diagnosis,
              every illness, and every injury is different. Our trained and
              compassionate team members value the quality of life of every
              patient and their caregivers.
            </p>
          </div>
          <div className="img-container">
            <img src="/images/rehabilitation-1.jpg" alt="Stock" />
          </div>
        </div>
      </section>
      <section className="rehabilitation-2">
        <div className="container padding-container col">
          <h1>
            We offer inpatient and outpatient rehabilitation services for
            individuals recovering from conditions such as:
          </h1>
          <div className="row">
            <ul>
              <li>Amputation</li>
              <li>Brain injury</li>
              <li>Stroke</li>
              <li>Multiple trauma and burns</li>
            </ul>
            <ul>
              <li>Cancer</li>
              <li>Temporal arteritis</li>
              <li>Bilateral joint replacement and hip fractures</li>
            </ul>
            <ul>
              <li>
                Neuromuscular disorders and degenerative neurological disorders,
                including Guillain-Barre Syndrome, Multiple Sclerosis and
                Amyotrophic Lateral Sclerosis
              </li>
            </ul>
            <ul>
              <li>
                Heart attack, congestive heart failure and coronary artery
                bypass graft
              </li>
              <li>Lupus</li>
              <li>Neuropathy</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <section className="rehabilitation-3">
        <div className="container padding-container col">
          <h1>Sports Medicine</h1>
          <p>
            Sports injuries affect athletes of every age and ability. Our team
            at Carnegie Tri-County Municipal Hospital tailors treatment specific
            to every patient. From sports physical therapy for the weekend
            warrior to rehabilitation for the serious athlete, our sports injury
            program strives to heal complex sports injuries and limit the
            likelihood of re-injury.
          </p>
          <p>
            Our rehabilitation team looks at the whole body so as not to just
            stop the pain but to address the underlying source of injury. We
            specialize in treatment around each athlete, utilizing therapies and
            training exercises that not only mimic the demands of their sport
            but also address the unique conditions of the specific position
            within their sport. Sports therapy exercises may include:
          </p>
          <div className="row">
            <ul>
              <li>High-level balance exercises</li>
              <li>High intensity interval training</li>
            </ul>
            <ul>
              <li>Plyometric training</li>
              <li>Power, agility, flexibility and speed activities</li>
            </ul>
          </div>
          <p>
            Sports injury rehab is an outpatient program with flexible hours to
            accommodate athletes with busy schedules. Our newly remodeled
            rehabilitation facility features new, state-of-the-art physical
            therapy equipment and comfortable private rooms. Our onsite
            radiology department enables us to serve all your recovery needs in
            one convenient location.
          </p>
        </div>
      </section> */}

      <section className="rehabilitation-4">
        <div className="container padding-container col">
          <div className="row">
            <div className="img-container">
              <img src="/images/rehabilitation-2.jpg" alt="Stock" />
            </div>
            <div className="col">
              <h1>Physical Therapy</h1>
              <p>
                Carnegie Tri-County Municipal Hospital’s physical therapy team
                understands the physical and mental challenges a patient may
                face during the healing process. We offer inpatient and
                outpatient services to the community. The treatment team works
                diligently with patients to achieve their optimal developmental
                and functional levels through one-on-one therapy and
                family/caregiver education. Carnegie Tri-County Municipal
                Hospital provides treatment for a variety of diagnoses,
                including:
              </p>
              <div className="row">
                <ul>
                  <li>Orthopedic Disorders</li>
                  <li>Spinal Dysfunction</li>
                  <li>Balance/Vestibular Disorders</li>
                  <li>Work-Related Injuries</li>
                </ul>
                <ul>
                  <li>Balance/Vestibular Disorders</li>
                  <li>Neurological Disorders</li>
                  <li>Wound Care</li>
                  <li>Sports Related Injuries</li>
                </ul>
              </div>
              <p>
                Our respiratory therapists are essential members of the
                multidisciplinary healthcare team, collaborating with the
                physicians and nurses to provide compassionate, high-quality
                care to all areas of the hospital. Our clinicians use high-tech
                equipment and the latest medical procedures to diagnose and
                treat our patients.
              </p>
            </div>
          </div>
          <p>
            After surgery, injury or illness, regaining strength, mobility and
            endurance are the first steps to returning to everyday life.
            Physical therapy begins with an initial consultation that assesses
            each patient’s challenges, capabilities and goals. A competent and
            caring physical therapist, who consistently guides each patient from
            the first visit through recovery, individually designs the therapy
            program with the patient and family.
          </p>
        </div>
      </section>
      <section className="rehabilitation-5">
        <div className="container padding-container col">
          <h1>
            Our goal is to achieve maximum results using a variety of physical
            therapy services, treatment may include:
          </h1>
          <div className="row">
            <ul>
              <li>Orthopedic Physical Therapy</li>
              <li>Industrial Rehabilitation</li>
              <li>Fitness Conditioning</li>
              <li>Total Joint Rehabilitation</li>
            </ul>
            <ul>
              <li>Sports Physical Therapy</li>

              <li>Instrumental Augmented Soft Tissue Mobilization (IASTM)</li>
            </ul>

            <ul>
              <li>Pre and Post-Surgery Rehabilitation</li>

              <li>Work Conditioning</li>
              <li>Spinal Rehabilitation</li>
            </ul>
          </div>
          <p>
            Carnegie Tri-County Municipal Hospital is pleased to offer quality
            therapy services in a convenient location for your outpatient
            rehabilitation needs. Our outpatient physical therapy department is
            fully equipped with exercise and conditioning equipment that will
            enable you to achieve maximum potential. For more information on
            outpatient physical therapy services or to schedule an appointment,
            please contact our office at (580) 654-1050 Ext. 2306.
          </p>
        </div>
      </section>
      <section className="rehabilitation-6">
        <div className="container padding-container col">
          <div className="row">
            <div className="col">
              <h1>Occupational Therapy</h1>
              <p>
                Occupational therapy at Carnegie Tri-County Municipal Hospital
                strives to help patients resume activities of daily living after
                experiencing a debilitating injury or illness. Occupational
                Therapy is an important service that will assist patients to
                achieve and maintain an independent lifestyle.
              </p>
              <p>
                After a comprehensive evaluation with our compassionate and
                experienced Occupational Therapist, patients, families, and
                therapists will work together to develop goals and create and
                personalized treatment plan that is comfortable for the patient.
                Occupational therapy at Carnegie Tri-County Municipal Hospital
                is known for giving patients the confidence and ability to
                perform everyday activities, including:
              </p>
              <div className="row">
                <ul>
                  <li>Eating</li>
                  <li>Dressing</li>
                  <li>Home Management</li>
                </ul>
                <ul>
                  <li>Bathing</li>
                  <li>Meal Preparation</li>
                </ul>
                <ul>
                  <li>Shopping</li>
                  <li>Community Mobility</li>
                </ul>
              </div>
            </div>
            <div className="img-container">
              <img src="/images/rehabilitation-3.jpg" alt="Stock" />
            </div>
          </div>
          <p>
            Using a wide range of interventions, our therapists adapt tasks and
            modify the environment to enhance each patient’s ability to function
            in their physical and social environment. They provide sensori-motor
            treatments for strength, endurance, range of motion, coordination
            and balance, as well as therapeutic activities for memory,
            orientation, cognitive integration and daily life skills.
          </p>
          <p>
            Our occupational therapists collaborate with patients and their
            support teams to create a personalized work plan, tailored to the
            particular functions of an individual’s day-to-day schedule.
          </p>
        </div>
      </section>
      {/* <Appointment /> */}
      <Footer />
    </div>
  );
};

export default Rehabilitation;
