import React from "react";
import { Link } from "react-router-dom";
import "../static/main.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiOutlineXMark } from "react-icons/hi2";
import { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";

const GeneralNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="general-nav">
      <nav className="container padding-container">
        <Link to="/" className="logo link">
          <img src="/images/logo.png" alt="Logo" />
          <h1>
            Carnegie Tri-County <br /> Municipal Hospital
          </h1>
        </Link>
        <ul className={isOpen ? "navOpen" : ""}>
          <li>
            <Link to="/" className="link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="link">
              About Us
            </Link>
          </li>
          <li id="services">
            <Link to="/services" className="link">
              Services
            </Link>
          </li>
          {/* <li>
            <Link to="/appointment" className="link">
              Appointment
            </Link>
          </li> */}
          <li>
            <Link to="/contact" className="link">
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              to="https://www.securebillpay.net/carnegietricounty"
              className="link"
            >
              Make a Payment
            </Link>
          </li>
          <li>
            <Link to="/employment" className="link">
              Employment
            </Link>
          </li>
          <li>
            <Link to="/clinic" className="clinic">
              Carnegie Medical Clinic
            </Link>
          </li>
        </ul>
        <div
          className={`hamburger-container ${
            isOpen ? "hidden" : "hamburger-container"
          }`}
          onClick={toggleMenu}
        >
          <RxHamburgerMenu className="hamburger" />
        </div>
        <div
          className={`close-hamburger-container ${
            !isOpen ? "hidden" : "hamburger-container"
          }`}
          onClick={toggleMenu}
        >
          <HiOutlineXMark className="close-hamburger" />
        </div>
      </nav>
    </header>
  );
};

export default GeneralNav;
